@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
    * {
        outline: none;
        font-family: 'Montserrat', sans-serif;
    }
}

.react-bootstrap-table-page-btns-ul {
    margin-bottom: 0px;
}
.close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
        display: none;
    }
}
.rangeslider__fill {
    background-color: #556ee6 !important;
}
.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    margin: 2em 0;
}
//Crypto enteries
.bs-select select {
    width: auto !important;
}

.apexcharts-canvas {
    text {
        fill: #adb5bd !important;
    }
}

//
// Columns
//

.card-columns {
    .card {
        margin-bottom: $grid-gutter-width;
    }

    @include media-breakpoint-up(sm) {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;

        .card {
            display: inline-block; // Don't let them vertically span multiple columns
            width: 100%; // Don't let their width change
        }
    }
}

//carousel
.carousel-custom {
    .carousel-dark {
        .carousel.slide {
            .carousel-indicators [data-bs-target] {
                background: #000;
                border-color: #fff;
            }
        }
    }
    .carousel.slide {
        .carousel-indicators [data-bs-target] {
            border: 1px solid #000;
            background: #fff;
            height: 2px;
            opacity: 0.4;
        }
        .carousel-indicators .active {
            opacity: 1;
        }
    }
}

//cursor-pointer
.cursor-pointer {
    cursor: pointer;
}

//form editor
.wysiwyg-custom {
    .rdw-link-modal {
        height: auto;
    }
    .rdw-embedded-modal-header {
        .rdw-embedded-modal-header-option {
            width: auto;
        }
    }
}

//form xeditable
.formXeditable-custom {
    button {
        margin-left: 5px;
    }
}

//User List
.dropdown-toggle {
    &:after {
        display: none !important;
    }
}

//react-table
.react_table {
    thead {
        tr {
            th {
                div:last-child {
                    min-height: 38px;
                }
            }
        }
    }
}
// CSS
.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%;
}
.c-view {
    text-align: center;
}
.form-control:disabled {
    background: #e1e1e1;
}
.foot-title {
    font-size: 20px;
    color: #112233;
}
#sidebar-menu ul li a {
    font-size: 16px;
}
.dropdown-menu .dropdown-item:hover {
    color: #1e3ed8;
}

/* remove validation icons */
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
    border-color: #ced4da;
    padding-right: 0.75em;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none !important;
    border-color: #ced4da;
    padding-right: 0.75em;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

/* 24 AUG 2023*/
#page-topbar {
    background-color: #1fa310;
}
.footer-page {
    background: #1fa310;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    font-size: 20px;
    padding: 3dvb;
}
// .pl-20{margin-left: 20px !important;}
.simplebar-bg {
    background-color: #efeaf9;
}
.page-header-user-dropdown:active {
    color: #fff !important;
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    color: #fff !important;
}

#sidebar-menu ul li a:hover {
    color: #062575 !important;
}

#sidebar-menu ul li a {
    border-bottom: 1px solid #1c2742;
}

body[data-sidebar='dark'] #sidebar-menu ul li a:hover i {
    color: #062575 !important;
}

body[data-sidebar='dark'] #sidebar-menu ul .mm-active a:hover i {
    color: #fff !important;
}
#sidebar-menu ul .mm-active a:hover {
    color: #fff !important;
}
.header-profile-user {
    height: 45px !important;
    width: 45px !important;
}
.header-box {
    margin: 0 25px;
}
table thead {
    background: #293552 !important;
    color: #fff !important;
    font-size: 18px !important;
}
table thead th {
    background: #293552 !important;
    color: #fff !important;
    font-size: 18px !important;
}
.table tbody tr {
    border-bottom: 1px solid;
    border-color: #ced4da;
    font-size: 18px;
    color: #112233 !important;
}
.card-header H4 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li .active span {
    color: #fff !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
    color: #293552 !important;
}

#sidebar-menu ul li .active {
    background: #293552 !important;
    color: #fff !important;
}

.outer-lg {
    background: #123;
    border-radius: 20px;
}
.inner-bg {
    background: #fff;
    padding: 20px !important;
}
.login-btn {
    background: #5b73e8;
    border-color: #495cba;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 10px 70px;
}

.outer-lg .form-label {
    font-size: 14px !important;
}
// .doc-mb-2{margin: 0 0 10px!important;}
.img-box {
    border: 1px solid #112233;
    padding: 20px;
    margin-bottom: 60px;
    border-radius: 10px;
    background: #efeaf9;
}
.forgot-pwd a {
    color: #5b73e8;
    font-size: 16px;
}
.forgot-pwd a:hover {
    color: #20a310;
}
.w-break {
    word-break: break-word !important;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    cursor: not-allowed !important;
    pointer-events: all;
}

.down-arrow {
    color: #20a310;
    font-size: 28px;
    cursor: pointer;
}
// New css
// CSS
.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%;
}
.c-view {
    text-align: center;
}
.form-control:disabled {
    background: #e1e1e1;
}
.foot-title {
    font-size: 20px;
    color: #112233;
}
#sidebar-menu ul li a {
    font-size: 16px;
}
.dropdown-menu .dropdown-item:hover {
    color: #1e3ed8;
}

/* remove validation icons */
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
    border-color: #ced4da;
    padding-right: 0.75em;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none !important;
    border-color: #ced4da;
    padding-right: 0.75em;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

/* 24 AUG 2023*/
#page-topbar {
    background-color: #1fa310;
}
.footer-page {
    background: #1fa310;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    font-size: 20px;
    padding: 3dvb;
}
// .pl-20{margin-left: 20px !important;}
.simplebar-bg {
    background-color: #efeaf9;
}
.page-header-user-dropdown:active {
    color: #fff !important;
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    color: #fff !important;
}

#sidebar-menu ul li a:hover {
    color: #062575 !important;
}

#sidebar-menu ul li a {
    border-bottom: 1px solid #1c2742;
}

body[data-sidebar='dark'] #sidebar-menu ul li a:hover i {
    color: #062575 !important;
}

body[data-sidebar='dark'] #sidebar-menu ul .mm-active a:hover i {
    color: #fff !important;
}
#sidebar-menu ul .mm-active a:hover {
    color: #fff !important;
}
.header-profile-user {
    height: 45px !important;
    width: 45px !important;
}
.header-box {
    margin: 0 25px;
}
table thead {
    background: #293552 !important;
    color: #fff !important;
    font-size: 18px !important;
}
table thead th {
    background: #293552 !important;
    color: #fff !important;
    font-size: 18px !important;
}
.table tbody tr {
    border-bottom: 1px solid;
    border-color: #ced4da;
    font-size: 18px;
    color: #112233 !important;
}
.card-header H4 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li .active span {
    color: #fff !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
    color: #293552 !important;
}

#sidebar-menu ul li .active {
    background: #293552 !important;
    color: #fff !important;
}

.outer-lg {
    background: #123;
    border-radius: 20px;
}
.inner-bg {
    background: #fff;
    padding: 20px !important;
}
.login-btn {
    background: #5b73e8;
    border-color: #495cba;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 10px 70px;
}

.outer-lg .form-label {
    font-size: 14px !important;
}
// .doc-mb-2{margin: 0 0 10px!important;}
.img-box {
    border: 1px solid #112233;
    padding: 20px;
    margin-bottom: 60px;
    border-radius: 10px;
    background: #efeaf9;
}
.forgot-pwd a {
    color: #5b73e8;
    font-size: 16px;
}
.forgot-pwd a:hover {
    color: #20a310;
}
.w-break {
    word-break: break-word !important;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    cursor: not-allowed !important;
    pointer-events: all;
    text-transform: uppercase;
}

.down-arrow {
    color: #20a310;
    font-size: 28px;
    cursor: pointer;
}
body[data-sidebar='dark'] .mm-active {
    color: #112233 !important;
}
body[data-sidebar='dark'] #sidebar-menu ul .mm-active a:hover {
    color: #fff !important;
}

// Added 12-01-2024 : Satish Baghel
body[data-sidebar='dark'] .mm-active > a {
    background-color: #293552 !important;
    color: #fff !important;
}

// body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu .mm-active .gr-active {

//     color: #fff !important;
//     background: #5b73e8 !important;
// }

// body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu .mm-active li a{
//     color: #fff !important;
//     background: red !important;
// }

// body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu .mm-active a:hover {
//     color: red !important;
// }
body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu .mm-active .active:hover {
    color: #fff !important;
}
body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu li a:hover {
    color: #112233 !important;
}

.w-break {
    word-break: break-all !important;
}
#vertical-menu-btn {
    color: #fff !important;
}

.card-body button.active {
    background: #20a310;
    border-color: #20a310;
}
.link-btn {
    background: #20a310;
    border-color: #20a310;
    // padding: 7px;
    color: #fff;
    border-radius: 5px;
    // font-size: 0.9rem !important;
    // margin: 0 5px;
    // text-wrap: nowrap;
}
.link-btn:hover {
    background: #20a310;
}
.link-btn:active,
.link-btn::after {
    background: #20a310;
}
.link-btn-black {
    background: #112233;
    padding: 7px;
    color: #fff;
    border-radius: 5px;
    font-size: 0.9rem !important;
    margin: 0 5px;
    text-wrap: nowrap;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.mt-2 {
    margin-top: 15px !important;
}
.hght {
    font-size: 20px;
    font-weight: bold;
}
.w-break {
    word-break: break-all !important;
}
.c-center {
    text-align: center;
}

.page-cur .disabled {
    cursor: not-allowed !important;
    pointer-events: all;
}

.form-control:disabled {
    cursor: not-allowed !important;
    pointer-events: all;
}

.t-height {
    height: 50px !important;
}

.del-icon {
    position: absolute;
    left: 24%;
    top: 12%;
}
.fs-box {
    font-size: 20px !important;
}

.bg-green {
    background: #20a310;
    border-color: #20a310;
}

.cal-icon {
    position: absolute !important;
    right: 28px !important;
    top: 36px !important;
    font-size: 24px !important;
}

.form-date .cal-icon {
    position: absolute !important;
    right: 19px !important;
    top: 6px !important;
    font-size: 24px !important;
}
.form-date-setup .cal-icon {
    top: 42px !important;
}
.form-date-setup-view  .form-control:focus {
    border-color: #d4d5d5 !important;
}

.form-date-setup-view .cal-icon {
    top: 7px !important;
}

.dis-cur input {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

.sub-btn{border: none;margin-top:10px;}
.sub-user-div td{background-color: #efeaf9;}
.sub-icn{padding-left:8px;}
.sub-main-div td{background-color: #eeeeee;text-align: left;font-weight: 600;}
.sub-main-div:hover{background-color: #eeeeee;text-align: left;}

@media only screen and (max-width: 1499px) {
    .res-tab-cap {
        display: table-caption;
    }
    .cust-ip {
        padding: 6px 7px;
    }
}
@media only screen and (max-width: 1260px) {
    .cust-ip {
        padding: 6px 4px;
    }
}
.cursor-allowed {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

.text-warning {
    color: #f1b44d !important;
    font-size: 20px;
    font-family: 'MYRIADPRO' !important;
}
.cart-note {
    padding: 0 5px;
    font-size: 20px;
    color: #fff;
    font-family: 'MYRIADPRO' !important;
}
.cnt-spce {
    text-align: right !important;
}

.cmplte-pay {
    background: #5b73e8;
    margin-bottom: 30px;
    color: #fff;
    border-radius: 5px;
    padding: 7px 0;
}
.checkbox-lg .form-check-input {
    top: 0.8rem;
    scale: 1.4;
    margin-right: 0.7rem;
}
.checkbox-lg .form-check-label {
    padding-top: 13px;
}

body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu li a:hover {
    color: #fff !important;
    background: #5b73e8;
}

#sidebar-menu ul li ul.sub-menu li a:hover {
    color: #fff !important;
    background: #5b73e8;
}

body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu .mm-active .sub-child:hover {
    color: #fff !important;
    background: #5b73e8;
}
.alias-user-highlight .form-label {
    color: #c100b2 !important;
    // font-weight: 600;
}

.alias-user-highlight {
    color: #c100b2 !important;
    font-weight: 600;
}

.form-check-input:disabled {
    pointer-events: auto;
    cursor: not-allowed !important;
}

.note-label {
    text-align: center;
    margin-bottom: 0;
}
.note-label small {
    font-size: 16px;
    color: red;
}
// .layout label{font-size: 16px;  font-family: 'Montserrat', sans-serif;}

.layout .radio-ip .form-check-input {
    width: 1.3em;
    height: 1.3em;
    margin-top: 0;
    vertical-align: middle;
}
.button-mr {
    margin: 0 20px;
}
.fw-600 {
    font-weight: 600;
}

// .css-1nmdiq5-menu {
//     max-height: 100px !important;
//     overflow: scroll;
// }
.form-label {
    font-size: 17px;
    color: #112233;
}
.breadcrumb-item > a {
    font-size: 17px;
    color: #112233;
}
.breadcrumb-item {
    font-size: 17px;
    color: #112233;
}
.fs-18px {
    font-size: 16px;
}
.mlr-2 {
    margin: 0 20px;
}

.form-check-input[type='radio'] {
    width: 1.2em;
    height: 1.2em;
}
.form-check-input[type='checkbox'] {
    width: 1.2em;
    height: 1.2em;
}

.contx .form-check-input[type='radio'] {
    width: 1.4em;
    height: 1.4em;
}

.new-contx .form-check-input[type='radio'] {
    width: 1.1em !important;
    height: 1.1em !important;
}

.contx .form-check-input[type='checkbox'] {
    width: 1em;
    height: 1em;
}

.club-selection .form-check-input[type='radio'] {
    width: 1.4em;
    height: 1.4em;
}

.club-selection .form-check-input[type='checkbox'] {
    width: 1em !important;
    height: 1em !important;
}

.btn-lg {
    font-size: 0.9rem;
}
.page-item .page-link {
    font-size: 16px;
}
.fs-14px {
    font-size: 14px;
}
.scroll-to-top {
    width: 47px !important;
    box-shadow: 0 9px 25px 0 #8480b147;
    border: 1px solid #e1e1e1 !important;
}
.report-note label {
    font-size: 18px;
    font-weight: bold;
}

.form-select:disabled {
    color: #495058;
    background-color: #e1e1e1;
    cursor: not-allowed !important;
    pointer-events: all !important;
}
.w-150px {
    width: 150px;
}
.w-100px {
    width: 100px;
}
.d-inflex {
    display: inline-flex;
}
body[data-sidebar='dark'] #sidebar-menu ul li a {
    color: #112233;
}
body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu li a {
    color: #112233;
}
.w-brak {
    word-break: break-all;
}

.show-box {
    background: #e4eee3;
    margin-bottom: 15px;
    margin: 15px 5px;
    border-radius: 5px;
    border: 1px solid #e4eee3;
    padding: 10px 0;
}
.show-box h5 {
    margin-bottom: 0;
    margin-top: 8px;
}
.type-select-box {
    border: 1px solid #b7b8b8;
    border-radius: 5px;
    width: 80%;
    margin: 10px auto auto;
    padding: 7px 0;
    background: #f9f9f9;
}
.club-type-select-box {
    border: 1px solid #b7b8b8;
    border-radius: 5px;
    width: 100%;
    margin: 10px auto auto;
    padding: 7px 0;
    background: #f9f9f9;
}
.sanction-box {
    border: 1px solid #b7b8b8;
    border-radius: 5px;
    background: #f9f9f9;
    width: 80%;
    margin: 10px auto 20px auto;
}
.blink_me {
    animation: blinker 2s linear infinite;
    font-weight: bold;
}


.block-str{
    border: 1px solid #ced4da !important;
    padding: 5px;
    margin-bottom: 25px !important;
    background: #f0f2f6;
    border-radius: 5px;
}

.ip-width .ljvOTl, .ip-width .bvOyhi{
    width: 80%; 
}
.border-box-v{
    border: 1px solid #ced4da;
    background: #fcfcfc;
    border-radius: 5px;
}
.rotate-view {
    padding: 0px 5px;
    margin-bottom: 10px;
}
.check-high {
    border-color: #293552;
}
@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}
.pl-10 {
    padding-left: 10px;
}
.wjres {
    width: 110px;
}
@media only screen and (max-width: 1699px) {
    .wres {
        width: 160px !important;
    }
}

@media only screen and (max-width: 1499px) {
    // .table-responsive .w-150px{width: 150px;}
    .w-100px {
        width: 100px;
    }
    .w-120px {
        width: 120px;
    }
    .wres {
        width: 160px !important;
    }
    .invalid-feedback {
        font-size: 12px;
    }
    .layout label {
        font-size: 16px;
    }
    .form-label {
        font-size: 16px;
    }
    .breadcrumb-item > a {
        font-size: 14px;
    }
    .breadcrumb-item {
        font-size: 14px;
    }
    // .css-1nmdiq5-menu {
    //     max-height: 120px !important;
    //     overflow: scroll;
    // }
}

@media only screen and (max-width: 991px) {
    .wres {
        width: auto;
    }
    .breadcrumb-item > a {
        font-size: 12px;
    }
    .breadcrumb-item {
        font-size: 12px;
    }
    .type-select-box {
        width: 100%;
    }
    .sanction-box {
        width: 100%;
    }
}

.w-40 {
    width: 40% !important;
}
.w-30 {
    width: 40% !important;
}
#sidebar-menu ul li ul.sub-menu li a {
    padding: 0.4rem 1.5rem 0.4rem 1.5rem;
}

.table {
    --bs-table-bg: #ffffff;
}
.bg-high {
    border-bottom-color: #bcbfc1 !important;
}

@media print {
    .p-display-none {
        display: none;
    }
}

.swal2-cancel {
    text-transform: uppercase;
}
.swal2-confirm {
    text-transform: uppercase;
}
.mr-10px {
    margin-right: 10px;
}

.cursor-default {
    cursor: default;
}

#sidebar-menu ul li ul.sub-menu li a {
    padding: 0.4rem 0.8rem 0.5rem 1rem;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
    padding: 0.4rem 1.5rem 0.4rem 2.5rem;
}

// .member-view .css-1nmdiq5-menu {
// overflow: hidden;
// min-height: 320px  !important;
// z-index: 999;
// position: unset  !important;
// }


.hr {
    color: inherit;
    background-color: currentColor;
    border: 1px solid;
    height: 2px;
    opacity: 0.25;
}

.w-10{width: 10%;}
.w-5{width: 5%;}
.w-10{width: 10%;}
.w-8{width: 8%;}
.mem-table tbody tr {
    font-size: 16px !important;
    color: #112233!important;
}
.txt-c{text-align: center;}

.mem-table  hr {
    margin: 5px 0;
    border-top: 1px solid #cab9b9;
}
.top-grand-box{
    background: #293552;
    font-size: 18px;
    padding: 15px;
}
.top-grand-box h5{
    color:white
}